@font-face {
    font-family: 'Din-2014';
    src: url('./assets/fonts/DIN\ Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'Din-Regular';
    src: url('./assets/fonts/DIN-2014_Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'Din-Medium';
    src: url('./assets/fonts/DIN\ Medium.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
@font-face {
    font-family: 'Din-light';
    src: url('./assets/fonts/DIN-2014_Regular.otf') format('opentype');
    font-weight:300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/Helvetica-1.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica ';
    src: url('./assets/fonts/Helvetica.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica light';
    src: url('./assets/fonts/Helvetica\ Light\ Condensed.otf') format('truetype');
    font-weight:100;
    font-style: normal;
  }
  @font-face {
    font-family: 'PT Sans Narrow';
    src: url('./assets/fonts/PT_Sans-Narrow-Web-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


@tailwind base;
@tailwind components;
@tailwind utilities;

  